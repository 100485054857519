import * as React from "react"
import Layout from "../../components/layout"
import { ImageViewer } from "react-image-viewer-dv"
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql } from "gatsby"
import Seo from "../../components/seo"
import './media.css'
import YoutubeEmbed from "../../components/YoutubeEmbed/YoutubeEmbed";

const Media = ({data}) => {
  return (
    <>
      <Seo title="Media" description="On this page you can find recordings of my compositions and you can browse through photos of me, made by Milagro Elstak."/>
      <Layout >
        <section className="main-media">
          <h2 className="sub-title">Compositions</h2>
          <div className="top">
              <div className="video-container">
                  <h4>Quentin Rychner - Sept pièces pour piano Op.1</h4>
                  <YoutubeEmbed embedId="yVlNDZSVIM4"/>
              </div>
              <div className="video-container">
              <h4>Quentin Rychner - Quatuor de bassons, Op. 8</h4>
                  <YoutubeEmbed embedId="E40I0T4T6hI" />
              </div>
          </div>
          <h1 className="sub-title">Photos</h1>
          <small>All  photos on this website are &copy; Milagro Elstak</small>
          <div className="bottom">
              {data.allFile.edges.map(image => {
                return (
                    <div className="photo-container" key={image.node.id}>
                        <ImageViewer > 
                            <GatsbyImage 
                                image={image.node.childImageSharp.gatsbyImageData} 
                                alt={image.node.base.split('-').join(' ').split('.')[0]}
                                placeholder="blurred"
                                height="100%"
                                className="photo"
                            />
                        </ImageViewer> 
                    </div>
                )
                })}
          </div>
        </section>
      </Layout>
    </>
  )
}

export default Media

export const pageQuery = graphql`
query MyQuery {
    allFile(filter: {relativeDirectory: {eq: "gallery-slider"}}) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          base
          id
        }
      }
    }
  }  
`